<template>
    <div class="verification-status-page">
        <template v-if="status">
            <div class="verification-status-page__content" v-if="status">
                <h1 v-if="status.title" class="verification-status-page__title">
                    {{ $t(status.title) }}
                </h1>
                <img
                    class="verification-status-page__image"
                    :src="require(`@/assets/images/verifications/statuses/${$route.params.status}.svg`)"
                    alt="Verification status"
                />
                <p v-if="status.message" class="verification-status-page__text">
                    {{ $t(status.message.key, status.message.parameters) }}
                </p>
            </div>
        </template>
    </div>
</template>

<script>
import iframeMixin from '@/plugins/mixin/iframe.mixin';
import supportChatUtils from '@/utils/support-chat.utils';

export default {
    name: 'verification-status-page',
    mixins: [iframeMixin],
    data() {
        return {
            status: null,
        };
    },
    methods: {
        loadData() {
            const { _v } = this.$route.query;

            if (!_v) {
                this.status ={
                    title: null,
                    message: {
                        key: 'The request is required to have the {parameter} parameter.',
                        parameters: { parameter: '_v' },
                    },
                };

                return;
            }

            const decodedServiceData = Buffer.from(_v, 'base64');
            const { title, message } = JSON.parse(decodedServiceData.toString());

            this.status = {
                title,
                message: {
                    key: message,
                    parameters: null,
                },
            }
        },
    },
    mounted() {
        this.loadData();
        
        if (this.itsAnIframe) {
            supportChatUtils.hideChat();
        }
    }
}
</script>

<style lang='scss'>
.verification-status-page {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 460px;
    min-height: 350px;

    &__content {
        text-align: center;
    }

    &__title {
        color: $general-black;
        font-size: 1.875rem;
        font-weight: 700;
        margin-bottom: 16px;
    }

    &__text {
        color: $general-black;
        font-size: 1rem;
    }

    &__image {
        margin-bottom: 16px;
        max-width: 155px;
        width: 100%;
    }
}
</style>
